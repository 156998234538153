import React from 'react';
import { string } from 'prop-types';
import { Button } from '@chakra-ui/react';
import { Link } from 'gatsby';

import { useGuest } from '@/context/guest';
import useInvitation from '@/hooks/useInvitation';

/**
 * Function to render QRCodeButton component
 * @return {JSX.Element | null}
 */
function QRCodeButton({ text, customQueries, ...rest }) {
  const { guest } = useGuest();
  const { code, name } = guest;

  const isInvitation = useInvitation();

  const finalURL = `/e-ticket?to=${encodeURIComponent(name)}&code=${encodeURIComponent(
    code,
  )}${customQueries}`;

  if (!isInvitation) return null;

  return (
    <Link to={finalURL} target="_blank">
      <Button
        size="xs"
        colorScheme="blackAlpha"
        bgColor="bgPrimary"
        color="mainColorText"
        {...rest}
      >
        {text}
      </Button>
    </Link>
  );
}

QRCodeButton.propTypes = {
  text: string,
  customQueries: string,
};

QRCodeButton.defaultProps = {
  text: 'Lihat QR Code',
  customQueries: '',
};

export default React.memo(QRCodeButton);
